import * as React from 'react';
import { Link } from 'gatsby';

// styles
const pageStyles = {
	color: '#232129',
	padding: '96px',
	fontFamily: '-apple-system, Roboto, sans-serif, serif',
};
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
};

const paragraphStyles = {
	marginBottom: 48,
};
const codeStyles = {
	color: '#8A6534',
	padding: 4,
	backgroundColor: '#FFF4DB',
	fontSize: '1.25rem',
	borderRadius: 4,
};

// markup
const NotFoundPage = () => {
	return (
		<main style={pageStyles}>
			<title>페이지를 찾지 못했습니다</title>
			<h1 style={headingStyles}>페이지를 찾지 못했습니다.</h1>
			<p style={paragraphStyles}>
				<Link to="/">홈화면으로</Link>.
			</p>
		</main>
	);
};

export default NotFoundPage;
